.border_none .el-select[data-v-5b6268fe] {
  width: 100% !important;
}
.center[data-v-5b6268fe] {
  text-align: center;
}
.border_none .el-input__inner[data-v-5b6268fe] {
  width: 100% !important;
  background-color: none !important;
}
.el-radio + .el-radio[data-v-5b6268fe] {
  margin-left: 7px;
}
.el-radio__label[data-v-5b6268fe] {
  font-size: 12px !important;
}
.input_width input[data-v-5b6268fe] {
  width: 60px !important;
  margin: 0 5px;
}
.width_big[data-v-5b6268fe] {
  width: 30%;
}
.width_small[data-v-5b6268fe] {
  width: 20%;
}
.width_medium[data-v-5b6268fe] {
  width: 18%;
}
.width_ss[data-v-5b6268fe] {
  width: 15%;
}
.width_xs[data-v-5b6268fe] {
  width: 13%;
}
.gary_box[data-v-5b6268fe] {
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box[data-v-5b6268fe] {
  background: #EBF5FF;
}
.el-date-editor.el-input[data-v-5b6268fe], .el-date-editor.el-input__inner[data-v-5b6268fe] {
  width: 180px !important;
}
.save_box[data-v-5b6268fe] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content[data-v-5b6268fe] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-5b6268fe] {
  line-height: 28px;
  font-size: 12px;
}
.content tr[data-v-5b6268fe] {
  padding: 0;
  margin: 0;
}
.content td[data-v-5b6268fe] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 28px;
  padding: 0 6px;
  color: #666;
  font-size: 12px;
}
.content input[data-v-5b6268fe] {
  width: 100%;
  line-height: 28px;
  text-align: right;
  font-size: 12px;
}
.left_k[data-v-5b6268fe] {
  text-indent: 2em;
}
.left_f[data-v-5b6268fe] {
  text-indent: 4em;
}
.left_indent[data-v-5b6268fe] {
  text-indent: 5em;
}
.center[data-v-5b6268fe] {
  text-align: center;
}
.left[data-v-5b6268fe] {
  text-align: left;
}
.right[data-v-5b6268fe] {
  text-align: right;
}
.weight[data-v-5b6268fe] {
  font-weight: 700;
}
.row_box[data-v-5b6268fe] {
  border-bottom: 1px solid #eee;
}
.big_box4[data-v-5b6268fe] {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
}
.qsly[data-v-5b6268fe] {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}